/* https://www.greenpeace.org.au/our-style/colour-usage/ */

$gp-green: #66cc00;
$gp-darkgreen: #049b4b;
$gp-lightgray: #e5e5e5;
$gp-darkgray: #353535;
$gp-red: #e03e1e;
$gp-yellow: rgb(255, 237, 0);
$gp-orange: rgb(232, 78, 15);
$gp-aqua: #07d6db;
$gp-blue: #2364c6;

$gp-rupture1: $gp-orange;
$gp-rupture2: white;

/* $link: $gp-green; */
