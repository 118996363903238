@import "vars";

body, .mapboxgl-map {
  font-family: 'Libre Franklin', sans-serif;
}

a, a:visited, a.button, a.button:visited {
  color: $gp-rupture1;
}

.title { /* bulma override */
  font-size: 1.7rem;
}

.is-danger.input, .is-danger.textarea {
  border-width: 2px;
}

.button.is-danger, .button.is-danger.is-hovered, .button.is-danger:hover {
  background-color: $gp-rupture1;
}

a:active, a:hover, a.button:active, a.button:hover {
  color: black;
}

.gp-invisible-text {
  text-indent: -99999px;
  position: absolute;
}

.gp-hidden {
  display: none;
}

.gp-popup {
  background-color: $gp-yellow;
  height: auto;
  position: absolute;
  top: 25px;
  left: 25px;
  width: 520px;
  margin-bottom: 25px;
  z-index: 3;
  box-shadow: 0px 1px 15px rgba(0,0,0,0.2);
       -o-box-shadow: 0px 1px 15px rgba(0,0,0,0.2);
  -ms-box-shadow: 0px 1px 15px rgba(0,0,0,0.2);
     -moz-box-shadow: 0px 1px 15px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0px 1px 15px rgba(0,0,0,0.2);
}

.gp-content {
  padding: 20px;
  margin-bottom: 45px;  /* make room for counter */
  /* 45px counter + 20px padding + 45px margin */
  max-height: calc(100vh - 90px);
  overflow-y: auto;
}

.gp-content.gp-no-counter {
  padding: 20px;
  margin-bottom: 0;
  /* 20px padding + 25px margin */
  max-height: calc(100vh - 45px);
  overflow-y: auto;
}

.gp-content .gp-subtitle-2 {
  color: $gp-rupture1;
}

.gp-content p {
  margin-bottom: 1.5rem;
}

.gp-content .gp-legal {
  font-size: 0.75rem;
}

.gp-popup-footer {
  margin-top: 20px;
}

.gp-popup-footer .gp-nav {
  float: left;
}

.gp-logo img {
  width: 180px;
}

.gp-counter {
  text-align: center;
  font-size: 1.5em;
  background-color: $gp-rupture2;
  color: black;
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 45px;
  line-height: 45px;
}

.gp-add-contribution {
  width: calc(100% - 1px);
  height: 45px;
  line-height: 45px;
  text-align: center;
  border: 1px solid lightgray;
  margin-top: 10px;
}


.gp-column-welcome-twitter,
.gp-column-welcome-form {
  padding: 0.25rem;
}

.button.gp-button-welcome-twitter,
.button.gp-button-welcome-twitter:hover,
.button.gp-button-welcome-twitter:active,
.button.gp-button-welcome-twitter:visited {
  border-color: transparent;
  /*background-color: rgb(29,161,242);
  color: #fff;*/
  background-color: white;
  color: black;
}

.button.gp-button-welcome-twitter:hover {
  /*background-color: darken(rgb(29,161,242), 10);*/
  background-color: darken(white, 10);
}

.button.gp-button-welcome-form,
.button.gp-button-welcome-form:hover,
.button.gp-button-welcome-form:active,
.button.gp-button-welcome-form:visited,
.button.gp-button-form-submit.is-link {
  background-color: $gp-rupture1;
  border-color: transparent;
  color: #fff;
}

.button.gp-button-welcome-form:hover, .button.gp-button-form-submit.is-link:hover {
  background-color: darken($gp-rupture1, 10);
}

.button.gp-button-form-submit.is-danger, .button.gp-button-form-submit.is-danger:hover {
  background-color: $gp-rupture1;
}

.button.gp-button-form-cancel.is-link {
  background-color: $gp-lightgray;
  color: black;
}

.button.gp-button-form-cancel.is-link:hover {
  background-color: darken($gp-lightgray, 10);
  color: black;
}

.gp-city-columns, .gp-city-input-column, .gp-send-columns, .gp-send-send-column {
  padding: 0;
  margin: 0 !important; /* because of .columns:last-child in bulma */
}

.gp-city-locate-column, .gp-send-cancel-column {
  padding: 0;
  margin: 0 0 0 .75rem;
}

.gp-locate-icon {
  color: $gp-green;
}

.gp-logo {
  text-align: right;
}

.gp-captcha.is-danger div[style] {
  border: 2px solid #ff3860;
}

.gp-contrib-name {
  font-weight: bold;
  /*font-size: 1.25rem;
  line-height: 1.25;*/
}

.gp-contrib-message {

}

.gp-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.3rem;
  cursor: pointer;
}

.gp-act-button {
  position: fixed;
  z-index: 3;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 0 6px 6px 0;
  font-size: 2rem;
  font-weight: bold;
  background-color: $gp-yellow;
  padding: 12px;
  cursor: pointer;
  box-shadow: 0px 1px 15px rgba(0,0,0,0.2);
       -o-box-shadow: 0px 1px 15px rgba(0,0,0,0.2);
  -ms-box-shadow: 0px 1px 15px rgba(0,0,0,0.2);
     -moz-box-shadow: 0px 1px 15px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0px 1px 15px rgba(0,0,0,0.2);
}

.gp-resume-form-button {
  position: fixed;
  z-index: 3;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0 0 6px 6px;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  font-size: 1rem;
  font-weight: bold;
  background-color: $gp-yellow;
  padding: 12px;
  cursor: pointer;
  box-shadow: 0px 1px 15px rgba(0,0,0,0.2);
       -o-box-shadow: 0px 1px 15px rgba(0,0,0,0.2);
  -ms-box-shadow: 0px 1px 15px rgba(0,0,0,0.2);
     -moz-box-shadow: 0px 1px 15px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0px 1px 15px rgba(0,0,0,0.2);
}

@media only screen and (max-device-width : 1024px) {
  .gp-responsive-container {
    width: 95%;
    margin: 0 auto;
    position: relative;
    left: 0;
  }
  .gp-popup {
    width: 100% !important;
    position: absolute !important;
    left: 0 !important;
    margin-bottom: 80px;  /* Make room for navigation elements footer on mobile. We could also use this trick: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ */
  }

  .gp-content {
    /* 45px counter + 20px padding + 80px margin */
    max-height: calc(100vh - 145px);
  }
}

@media only screen and (max-device-width : 768px) { /* follow bulma breakpoints */
  .gp-city-locate-column, .gp-send-cancel-column {
    margin: .75rem 0 0 0;
  }
}

/* https://reactcommunity.org/react-transition-group/css-transition */
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0;
  transition: opacity 250ms ease-in;
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}


#gp-map {
  position: absolute;
  left: 0;
  right: 0;
  width:100%;
  height:100%;
}

.gp-marker {
  background-image: url('assets/Pointeur-couleur.png');
  background-size: cover;
  width: 64px;
  height: 77px;
  cursor: pointer;
}

.mapboxgl-popup-content {
  max-width: 300px;
}

.mapboxgl-popup-content .name {
  font-weight: bold;
}

.gp-bo-content {
  margin: 15px;
}

.gp-bo-moderation {
  text-align: center;
}

.gp-bo-subtitle {
  font-weight: bold;
  margin: 10px 0;
}

.gp-bo-identifiers {
  max-width: 500px;
}

.gp-bo-multimoderation {
  float: right;
}

.gp-bo-table {
  margin-top: 15px;
  clear: both;
}

#gp-bo-minimap {
  width: 100%;
  height: 400px;
}
